import { OrbitControls, Preload } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import React, { Suspense, useEffect, useState } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import CanvasLoader from '../Loader';

const Earth = () => {
  const [earthModel, setEarthModel] = useState(null);

  useEffect(() => {
    const loader = new GLTFLoader();
    loader.load('./planet/scene.gltf', (gltf) => {
      setEarthModel(gltf.scene);
    }, undefined, (error) => {
      console.error('An error occurred while loading the GLTF model:', error);
    });
  }, []);

  if (!earthModel) {
    return null; // Render nothing if the model is not loaded yet
  }

  return (
    <mesh>
      <primitive object={earthModel} scale={2} position-y={0} rotation-y={0} />
    </mesh>
  );
};

const EarthCanvas = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 600px)");
    setIsMobile(mediaQuery.matches);

    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  return (
    <div style={isMobile ? { height: "300px", cursor: "pointer" } : { height: '450px', cursor: "pointer" }}>
      <Canvas shadows frameloop="demand" camera={{ position: [-4, 3, 10], fov: 45 }}>
        <ambientLight intensity={3} />
        <OrbitControls autoRotate enableZoom={false} maxPolarAngle={Math.PI / 2} minPolarAngle={Math.PI / 2} />
        <Suspense fallback={<CanvasLoader />}>
          <Earth />
          <Preload all />
        </Suspense>
      </Canvas>
    </div>
  );
};

export default EarthCanvas;
